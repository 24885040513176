import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./herepay.jpg"
								alt="herepay"
								className="work-image"
							/>
							<div className="work-title">Herepay Sdn Bhd</div>
							<div className="work-subtitle">
								Senior Software Engineer
							</div>
							<div className="work-duration">Jan 24 - Present</div>
						</div>

						<div className="work">
							<img
								src="./igw.png"
								alt="igw"
								className="work-image"
							/>
							<div className="work-title">Ingeniworks Sdn Bhd</div>
							<div className="work-subtitle">
								Lead Developer cum Devops Engineer
							</div>
							<div className="work-duration">Jan 23 - Sept 23</div>
						</div>

						<div className="work">
							<img
								src="./igw.png"
								alt="igw"
								className="work-image"
							/>
							<div className="work-title">Ingeniworks Sdn Bhd</div>
							<div className="work-subtitle">
								Software Engineer
							</div>
							<div className="work-duration">Mar 22 - Jan 23</div>
						</div>

						<div className="work">
							<img
								src="./ost.png"
								alt="ost"
								className="work-image"
							/>
							<div className="work-title">Opensoft Sdn Bhd</div>
							<div className="work-subtitle">
								Application Engineer
							</div>
							<div className="work-duration">Feb 22 - Mar 22</div>
						</div>

						<div className="work">
							<img
								src="./looka.jpg"
								alt="looka"
								className="work-image"
							/>
							<div className="work-title">Looka MY</div>
							<div className="work-subtitle">
								Junior Software Engineer
							</div>
							<div className="work-duration">Mar 21 - Feb 22</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
