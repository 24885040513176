const SEO = [
	{
		page: "home",
		description:
			"A Software engineer that focus more on backend development that powered all the awesome webapps i love. Making sure everything runs like a well-oiled machine, zippy fast and totally secure. Always practices the best technique!",
		keywords: ["Aliff Rosli", "Muhammad Aliff Bin Rosli", "aleprosli", "Software Alor Setar"],
	},

	{
		page: "about",
		description:
			"Seorang coder dimana saya bosan saya belajar buat kek, saya invest membeli tools dan bahan yang diperlukan untuk membuat KEK yang sedap. Enjoying eating a delicious cake feat coffee during the code is totally superb",
		keywords: ["Coder Alor Setar", "Software Engineer Alor Setar", "Aliff Rosli", "aleprosli", "Muhammad Aliff Bin Rosli", "Cake" , "Kek Alor Setar"],
	},

];

export default SEO;
