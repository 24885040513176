const INFO = {
	main: {
		title: "Portfolio by aleprosli",
		name: "Aliff Rosli",
		email: "aliff.rosli96@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/aleprosli",
		linkedin: "https://linkedin.com/in/aleprosli",
		instagram: "https://instagram.com/aleprosli",
		facebook: "https://facebook.com/aleprosli",
	},

	homepage: {
		title: "Backend enthusiasm, and amateur baker.",
		description:
			"A Software engineer that focus more on backend development that powered all the awesome webapps i love. Making sure everything runs like a well-oiled machine, zippy fast and totally secure. Always practices the best technique!",
	},

	about: {
		title: "I’m Aliff Rosli. Aor Pipol.",
		description:
			"Seorang coder dimana saya bosan saya belajar buat kek, saya invest membeli tools dan bahan yang diperlukan untuk membuat KEK yang sedap. Enjoying eating a delicious cake feat coffee during the code is totally superb",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Project 1",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 2",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 3",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 4",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 5",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},
	],
};

export default INFO;
